@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: none;
}

*::selection {
  background: initial;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  user-select:none;
  overflow: hidden !important;
}

body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 99999999999;
  background: #ff00003b;
  pointer-events: none;
  transition: all 2s ease;
}

body[data-sepia="true"]:before {
  opacity: 0.8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, "Consolas", Courier New, monospace;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.w-max {
  width: max-content;
}

.h-max {
  height: max-content;
}

.smoothsc {
  scroll-behavior: smooth;
}

input {
  border: none;
  outline: none;
}

input::selection {
  background: #0074ff;
  color: #fefefe;
}

.redbd {
  box-sizing: border-box;
  border: solid 1px red;
}

.redbd0 {
  border: solid 1px red;
}

.blbd {
  box-sizing: border-box;
  border: solid 1px blue;
}

.blbd0 {
  border: solid 1px blue;
}

img[data-invert='true'], svg[data-invert='true'] {
  filter: invert(1);
}

img[data-rounded='true'], svg[data-rounded='true'] {
  border-radius: 1000px;
}

img[data-flip='true'], svg[data-flip='true'] {
  transform: scale(-1, 1);
}

.uicon img {
  transform-origin: center;
  transition: 400ms ease-in-out;
}


.uicon img[data-click="true"]:active{
  transform: scale(0.8);
  transition: 0.1s ease;
}

.desktop {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 39px);
  top: 0;
}


.dpShad {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}

.dpShad0 {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
}

.mdShad {
  box-shadow: 0 0 8px rgba(41, 41, 41, 0.1);
}

.mdShad0 {
  filter: drop-shadow(0 0 8px rgba(41, 41, 41, 0.1));
}

.ltShad {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
} 
  
.ltShad0 {
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));
}

.prtclk * {
  pointer-events: none;
}

*[data-prtclk="true"] {
  pointer-events: none !important;
}

.nopt {
  pointer-events: none;
}

.handcr {
  cursor: pointer;
}

.none {
  display: none !important;
}


.text-xss{
  font-size: 12px;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.thinScroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

.thinScroll:hover::-webkit-scrollbar-thumb {
  background: rgba(32, 24, 148, 0.4);
}

.lightScroll:hover::-webkit-scrollbar-thumb {
  background: rgba(162, 159, 209, 0.4);
}

.thinScroll::-webkit-scrollbar-thumb {
  width: 2px;
  height: 2px;
  border-radius: 10px;
  background-color: transparent;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hvdark:hover {
  background: rgba(0, 0, 0, 0.08);
  transition: all 200ms ease-in-out;
}


#loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  z-index: 99999999999999;
  display: grid;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.circledots {
  position: relative;
  top: 0;
  left: 0;
  transform: scale(.9);
  width: 50px;
  height: 50px;
  margin: auto;
}
.circledots .circle {
  position: absolute;
  width: 38px;
  height: 38px;
  opacity: 0;
  border-radius: 100px;
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 5.5s;
}
.circledots .circle:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  clip-path: circle(100%);
  border-radius: 100px;
  background: #fff;
}
.circledots .circle:nth-child(2) {
  animation-delay: 240ms;
}
.circledots .circle:nth-child(3) {
  animation-delay: 480ms;
}
.circledots .circle:nth-child(4) {
  animation-delay: 720ms;
}
.circledots .circle:nth-child(5) {
  animation-delay: 960ms;
}
@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}

#loads {
  fill: #0178D4 !important;
}
a {
    text-decoration: none;
    color: #0080E1;
}
#textpad {
    font-family: Consolas , monospace;
    font-size: 18px;
    font-weight: 500;
}
  
.acol {
  color: #aaa;
}

#probtn{
  display: none;
}

#warningmsg{
  display: none;
}

@media screen and (max-width: 650px) {
  body[data-force="false"] #root {
    display: none;
  }

  body[data-force="false"]{
    display: grid;
    background: #2b2a2f;
    place-items: center;
    overflow: hidden;
  }

  body[data-force="false"]::before{
    display: none;
  }

  body[data-force="false"] #warningmsg{
    display: block;
    font-size: 1.6rem;
    color: #fefefe;
    font-family: sans-serif;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: left;
    word-spacing: 4px;
    line-height: 48px;
    inset: 50px;
    position: absolute;
    pointer-events: none;
  }

  body[data-force="false"] #probtn{
    display: block;
    color: #aaa;
    font-weight: 600;
    font-size: 1.2em;
    padding: 16px;
    background: #16161b;
    border-radius: 8px;
    border: none;
  }  

}
